<template>
  <div>
    <b-row class="mt-2">
      <b-col cols="12">
        <b-btn @click="value.push({})" class="mb-3" variant="secondary">{{
          $t("add_parent_info")
        }}</b-btn>

        <b-table
          class="mt-2"
          :fields="fields"
          :items="value"
          borderless
          v-if="value.length"
        >
          <template #cell(parentName)="{ item }">
            <validation-provider
              rules="required"
              v-slot="{ errors }"
              :name="$t('name')"
            >
              <b-form-input v-model="item.parentName" trim />
              <p v-for="error in errors" :key="error" class="text-danger mb-0">
                {{ error }}
              </p>
            </validation-provider>
          </template>
          <template #cell(relationship)="{ item }">
            <b-form-input v-model="item.relationship" trim />
          </template>
          <template #cell(email)="{ item }">
            <validation-provider
              :rules="{
                email: true,
                required: !!item.receiveEmails,
              }"
              v-slot="{ errors }"
              :name="$t('email')"
            >
              <b-form-input v-model="item.email" trim />
              <p v-for="error in errors" :key="error" class="text-danger mb-0">
                {{ error }}
              </p>
            </validation-provider>
          </template>
          <template #cell(phone)="{ item }">
            <b-form-input v-model="item.phone" trim />
          </template>
          <template #cell(receiveEmails)="{ item }">
            <b-form-checkbox v-model="item.receiveEmails" />
          </template>
          <template #cell(action)="{ item }">
            <div class="text-right">
              <b-link>
                <b-icon-trash
                  @click.stop.prevent="value.splice(value.indexOf(item), 1)"
                />
              </b-link>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BIconTrash } from "bootstrap-vue";

export default {
  components: {
    BIconTrash,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    fields() {
      return [
        { label: this.$t("name"), key: "parentName", thStyle: "width: 20%" },
        {
          label: this.$t("relationship"),
          key: "relationship",
          thStyle: "width: 20%",
        },
        { label: this.$t("email"), key: "email", thStyle: "width: 20%" },
        { label: this.$t("phone"), key: "phone", thStyle: "width: 20%" },
        {
          label: this.$t("wants_to_receive_emails"),
          key: "receiveEmails",
          thStyle: "width: 15%",
        },
        {
          label: "",
          key: "action",
          thStyle: "width: 5%",
        },
      ];
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
