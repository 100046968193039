export const countryHelper = {
    computed: {
        countries() {
            var currentLocale = this.$i18n.locale;
            return require(`./locales/countries/${currentLocale}`);
        },
    },
    methods: {
        getCountryNameById(id) {
            if (this.countries && id) {
                var country = this.countries.find((x) => x.id == id);
                if (country) return country.name;
            }
            return null;
        },
    },
};
