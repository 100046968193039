<template>
  <b-row>
    <b-col cols="12" md="6">
      <template
        v-for="field in formModule.fields.filter(
          (x, i, a) => i < Math.ceil(a.length / 2)
        )"
      >
        <template v-if="field.key === debtCollectionFieldKey">
          <form-field-input
            v-if="field.isVisible"
            :key="field.key"
            :field="field"
            :formConfig="formConfig"
            :input="input"
            @input="handleFinancialDetailsInput"
          />
        </template>
        <template v-else>
          <form-field-input
            v-if="field.isVisible && isDebtCollectionEnabled"
            :key="field.key"
            :field="field"
            :formConfig="formConfig"
            :input="input"
            @input="handleFinancialDetailsInput"
          />
        </template>
      </template>
    </b-col>
    <b-col cols="12" md="6">
      <template
        v-for="field in formModule.fields.filter(
          (x, i, a) => i >= Math.ceil(a.length / 2)
        )"
      >
        <template v-if="field.key === debtCollectionFieldKey">
          <form-field-input
            v-if="field.isVisible"
            :key="field.key"
            :field="field"
            :formConfig="formConfig"
            :input="input"
            @input="handleFinancialDetailsInput"
          />
        </template>
        <template v-else>
          <form-field-input
            v-if="field.isVisible && isDebtCollectionEnabled"
            :key="field.key"
            :field="field"
            :formConfig="formConfig"
            :input="input"
            @input="handleFinancialDetailsInput"
          />
        </template>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import moduleCommonMixin from "./moduleCommonMixin";

export default {
  mixins: [moduleCommonMixin],
  props: {
    formConfig: {
      type: Object,
      required: true,
    },
    formModule: {
      type: Object,
      required: true,
    },
    input: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      inputValue: null, //not used but required by vee-validate
    };
  },
  computed: {
    debtCollectionFieldKey() {
      return "BillingInfoDto.debtCollection";
    },
    ibanFieldKey() {
      return "BillingInfoDto.iban";
    },
    bicFieldKey() {
      return "BillingInfoDto.bic";
    },
    isDebtCollectionEnabled() {
      return this.input.BillingInfoDto?.debtCollection;
    },
  },
  created() {
    if (!this.input.BillingInfoDto) {
      this.$set(this.input, "BillingInfoDto", { });
    }
  },
  methods: {
    handleFinancialDetailsInput(inputObj) {
      if (inputObj.key === this.debtCollectionFieldKey) {
        var ibanField = this.getFormField(this.ibanFieldKey);
        if (ibanField != null) {
          ibanField.required = !!inputObj.value;
        }
      }

      if (inputObj.key === this.ibanFieldKey) {
        var bicField = this.getFormField(this.bicFieldKey);

        if (bicField != null) {
          var isDutchIban =
            inputObj.value && inputObj.value.toLowerCase().startsWith("nl");

          bicField.isVisible = !isDutchIban;

          if (isDutchIban) {
            this.handleFieldInput({ key: this.bicFieldKey, value: "" });
          }
        }
      }

      return this.handleFieldInput(inputObj);
    },
  },
};
</script>