import { format, parseISO, isValid } from "date-fns";
import nl from "date-fns/locale/nl";
import {
    displayShortDateFormat,
    displayShortDateNoYearFormat,
} from "./constants";

export const dateNoYearFilter = function (value, emptyChar = "") {
    if (!value) return emptyChar;
    return format(value, displayShortDateNoYearFormat);
};

export function _getDefaultDate(date) {
    return isValid(date) ? date : null;
}

export function _parseDateIso(value) {
    if (!value) {
        return null;
    }

    if (value instanceof Date) {
        return _getDefaultDate(value);
    }

    let date = parseISO(value);
    return _getDefaultDate(date);
}

export function shortDateFilter(value, dateFormat) {
    let date = _parseDateIso(value);
    let result = date
        ? format(date, dateFormat || displayShortDateFormat, { locale: nl })
        : "-";
    return result;
}

export function utcShortDateFilter(value, dateFormat) {
    if (!value) {
        return null;
    }
    let parts = value.split("-");
    let date = new Date(
        Date.UTC(
            Number.parseInt(parts[0]),
            Number.parseInt(parts[1] - 1),
            Number.parseInt(parts[2]),
            0,
            0,
            0
        )
    );
    let result = isValid(date)
        ? format(date, dateFormat || displayShortDateFormat)
        : "-";
    return result;
}

export const currencyEuro = function (value, displayZero = false) {
    if (displayZero) {
        if (!value || typeof value !== "number") {
            value = 0;
        }
    } else {
        if (!value) return `-`;
        if (typeof value !== "number") {
            return value;
        }
    }

    return `€ ${Number(value)
        .toFixed(2)
        .toString()
        .replace(".", ",")}`;
};

export const formatTimeFilter = function (value) {
    if (!value) return "";
    let arr = value.split(":");
    if (arr.length >= 2) return `${arr[0]}:${arr[1]}`;
    return value;
};

export function defaultValueFilter(value) {
    return value ? value : "-";
}
