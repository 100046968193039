<template>
  <validation-provider
    :name="fieldName || label"
    :rules="rules"
    :vid="vid"
    v-slot="validationContext"
    slim
    ref="vProvider"
    :skipIfEmpty="false"
  >
    <b-form-group
      v-bind="$attrs"
      :label="fieldLabel"
      :state="getValidationState(validationContext)"
      :label-cols-sm="labelColsSm"
      :label-class="labelClass"
    >
      <template #label>
        {{ fieldLabel }}
        <span v-if="description" v-b-popover.hover.top="description">
          <b-icon-question-circle variant="info" />
        </span>
      </template>

      <slot>
        <b-input-group
          :class="getValidationClass(validationContext)"
          class="date-input-group"
        >
          <b-form-input
            :value="parsedValue"
            type="text"
            :state="getValidationState(validationContext)"
            @keydown="checkInputValue"
            @input="handleInputValue"
            :placeholder="$t('date_placeholder')"
            :class="`date-input date-input-${_uid}`"
          />

          <b-input-group-append v-if="showPicker">
            <b-datepicker
              :min="min"
              :max="max"
              right
              start-weekday="1"
              button-only
              @input="handlePickerValue"
              :value="parsedPickerValue"
              :locale="$i18n.locale"
            />
          </b-input-group-append>
        </b-input-group>
        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </slot>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { baseValidatableMixin } from "@/shared";
import { BIconQuestionCircle } from "bootstrap-vue";

export default {
  components: { BIconQuestionCircle },
  mixins: [baseValidatableMixin],
  data: () => ({
    datePickerFormat: "yyyy-MM-dd",
  }),
  props: {
    min: null,
    max: null,
    dateFormat: {
      type: String,
      default: "dd-MM-yyyy",
    },
    rules: {
      type: Object,
      default: () => ({
        required: true,
        regex: /((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})$/,
      }),
    },
    description: {
      type: String,
    },
    showPicker: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    checkInputValue($event) {
      if (this.value && $event.key == "-" && this.value.endsWith("-")) {
        $event.preventDefault();
      }
    },
    handleInputValue(val) {
      if (!val || val.length < this.dateFormat.length) {
        if (val && (val.length == 2 || val.length == 5)) {
          val = val + "-";
        }

        return;
      }
      let date = this.parseDateFormat(val, this.dateFormat);
      let utc = this.isoDateToUtc(date);
      let result = this.formatDateToIsoString(utc);
      
      this.$emit("input", result);
    },
    handlePickerValue(val) {
      if (val) {
        let date = this.parseDateIso(val);
        let utc = this.isoDateToUtc(date);
        let result = this.formatDateToIsoString(utc);
        this.$emit("input", result);
      } else {
        this.$emit("input", null);
      }
    },
    shortDateFilter(value, dateFormat) {
      if (!value || value.length < dateFormat.length) {
        return value;
      }
      let date = this.parseDateIso(value);
      return date ? this.formatDate(date, dateFormat) : value;
    },
  },
  computed: {
    parsedValue() {
      return this.shortDateFilter(this.value, this.dateFormat);
    },
    parsedPickerValue() {
      if (!this.value) {
        return "";
      }
      let date = this.parseDateIso(this.value);
      return date
        ? this.shortDateFilter(this.value, this.datePickerFormat)
        : "";
    },
  },
  mounted() {
    let me = this;
    window.addEventListener("update-date-input-" + this._uid, function (e) {
      me.handleInputValue(e.detail.date);
    });
    window.dispatchEvent(
      new CustomEvent("date-input-loaded", {
        detail: {
          id: this._uid,
        },
      })
    );
  },
};
</script>
