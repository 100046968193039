export const baseValidatableMixin = {
    props: {
        label: {
            type: String,
            default: null
        },
        fieldName: {
            type: String,
            default: null
        },
        rules: {
            type: Object | String,
            default: () => ({ required: true })
        },
        vid: {
            type: String,
            default: null
        },
        asterix: {
            type: Boolean,
            default: false
        },
        labelColsSm: {
            type: String,
            default: '5'
        },
        labelClass: {
            type: String,
            default: ''
        },
        controlClass: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: null
        },
        validatable: {
            type: Boolean,
            default: false
        },
        value: null
    },
    computed: {
        fieldLabel() {
            return this.label + (this.asterix ? ' *' : '');
        }
    },
    methods: {
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        getValidationClass({ dirty, validated, valid = null }) {
            if (dirty || validated) {
                return valid ? 'is-valid' : 'is-invalid';
            }
            return '';
        },
    }
};
