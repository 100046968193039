import Vue from 'vue'
import App from './App.vue'
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

var messages = {};
const localeFiles = require.context(
  "./locales",
  true,
  /[A-Za-z0-9-_,\s]+\.json$/i
);

localeFiles.keys()
  .filter((key) => !key.includes("countries"))
  .forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const localeName = matched[1];
      messages[localeName] = localeFiles(key);
    }
  });

const i18n = new VueI18n({
  locale: 'en-GB',
  fallbackLocale: 'en-GB',
  messages: messages,
  silentTranslationWarn: true
});

import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)

import { dateTimeService } from "@/services/dateTimeService";
Vue.mixin(dateTimeService);

import BaseTextRow from "@/components/BaseTextRow";
Vue.component("base-text-row", BaseTextRow);

import BaseDatePicker from "@/components/BaseDatePicker";
Vue.component("base-date-picker", BaseDatePicker);

import ImageInput from "@/components/ImageInput";
Vue.component("image-input", ImageInput);

import FormFieldInput from "@/components/FormFieldInput";
Vue.component("form-field-input", FormFieldInput);

import ParentInfoModule from "@/components/ParentInfoModule";
Vue.component("parent-info-module", ParentInfoModule);

import FilesModule from "@/components/FilesModule";
Vue.component("files-module", FilesModule);

import AddressModule from "@/components/AddressModule";
Vue.component("address-module", AddressModule);

import FinancialDetailsModule from "@/components/FinancialDetailsModule";
Vue.component("financial-details-module", FinancialDetailsModule);

import MembershipModule from "@/components/MembershipModule";
Vue.component("membership-module", MembershipModule);

import ServicesModule from "@/components/ServicesModule";
Vue.component("services-module", ServicesModule);

import RegistrationForm from "@/components/RegistrationForm";
Vue.component("registration-form", RegistrationForm);

import ConfirmationForm from "@/components/ConfirmationForm";
Vue.component("confirmation-form", ConfirmationForm);

import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);

import { email, min, max, regex } from 'vee-validate/dist/rules';
import { validateIbanLength, validateIban } from '@/shared/ibanValidator';
extend('email', email);
extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined, false].indexOf(value) === -1
    };
  },
  computesRequired: true
});
extend("min", min);
extend("max", max);
extend("regex", regex);

extend('lowcase', {
  validate(value) {
    if (!value) {
      return true;
    }
    return value.match(/[a-z]/g) !== null;
  },
  message: (fieldName) => i18n.t("rules.lowercase_required", { _field_: fieldName })
});

extend('upcase', {
  validate(value) {
    if (!value) {
      return true;
    }
    return value.match(/[A-Z]/g) !== null;
  },
  message: (fieldName) => {
    return i18n.t("rules.uppercase_required", { _field_: fieldName })
  }
});

extend('requireDigit', {
  validate(value) {
    if (!value) {
      return true;
    }
    return value.match(/[0-9]/g) !== null;
  },
  message: (fieldName) => i18n.t("rules.digit_required", { _field_: fieldName })
});

extend('requireNonAlphaNum', {
  validate(value) {
    if (!value) {
      return true;
    }
    return value.match(/[^a-zA-Z\d\s:]/g) !== null;
  },
  message: (fieldName) => i18n.t("rules.non_alpha_numeric", { _field_: fieldName })
});

extend('ibanLength', {
  validate(value) {
    if (!value) {
      return true;
    }
    return validateIbanLength(value);
  },
  message: () => {
    var msg = i18n.t("rules.invalid_iban_length")
    return msg
  }
});

extend('iban', {
  validate(value) {
    if (!value) {
      return true;
    }
    return validateIban(value);
  },
  message: () => i18n.t("rules.invalid_iban")
});

import en from 'vee-validate/dist/locale/en.json';
import nl from 'vee-validate/dist/locale/nl.json';
import fr from 'vee-validate/dist/locale/fr.json';
import de from 'vee-validate/dist/locale/de.json';

localize({
  en,
  nl,
  fr,
  de
});

new Vue({
  i18n,
  render: h => h(App),
}).$mount("registration-form-entry");