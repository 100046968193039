<template>
  <div>
    <b-row class="mt-2">
      <b-col cols="12">
        <b-btn @click="value.push({})" class="mb-3" variant="secondary">{{
          $t("add_file")
        }}</b-btn>

        <b-table
          class="mt-2"
          :fields="fields"
          :items="value"
          borderless
          v-if="value.length"
        >
          <template #cell(file)="{ item }">
            <validation-provider
              rules="required"
              v-slot="{ errors }"
              name="Name"
            >
              <b-form-file
                v-model="item.file"
                @input="prepareBase64(item)"
                :placeholder="$t('choose_file')"
                :drop-placeholder="$t('choose_file')"
                plain
              ></b-form-file>
              <p v-for="error in errors" :key="error" class="text-danger mb-0">
                {{ error }}
              </p>
            </validation-provider>
          </template>
          <template #cell(action)="{ item }">
            <div class="text-right">
              <b-link>
                <b-icon-trash
                  @click.stop.prevent="value.splice(value.indexOf(item), 1)"
                />
              </b-link>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BIconTrash } from "bootstrap-vue";

export default {
  components: {
    BIconTrash,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    fields() {
      return [
        { label: "", key: "file" },
        {
          label: "",
          key: "action",
          thStyle: "width: 5%",
        },
      ];
    },
  },
  methods: {
    async prepareBase64(item) {
      var reader = new FileReader();
      reader.readAsDataURL(item.file);

      reader.onload = () => {
        item.base64 = reader.result.toString().replace(/^.*,/, '');
        item.fileName = item.file.name;
        item.contentType = item.file.type;
      };
      reader.onerror = function(error) {
        console.log("Error: ", error);
      };
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
