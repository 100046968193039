export default {
    methods: {
        handleFieldInput(inputObj) {
            if (inputObj.customFieldId) {
                inputObj.value = {
                    key: inputObj.customFieldId,
                    value: inputObj.value,
                };
            }

            this.setProp(this.input, inputObj.key.split("."), inputObj.value);
        },
        setProp(obj, props, value) {
            const prop = props.shift();

            if (prop.includes("[")) {
                var arrayPropName = prop.slice(0, prop.indexOf("[") - prop.length);
                
                var index = prop.slice(prop.indexOf("[") + 1, prop.indexOf("]"))

                if (!obj[arrayPropName]) {
                    this.$set(obj, arrayPropName, []);
                }
                
                obj[arrayPropName][index] = value
                return;
            }

            if (!obj[prop]) {
                this.$set(obj, prop, {});
            }

            if (props.length > 0) {
                this.setProp(obj[prop], props, value);
                return;
            }

            this.$set(obj, prop, value);
        },
        getFormField(key) {
            return this.formModule.fields.find((e) => e.key == key);
        },
    }
}