export const genderEnum = {
    data: function () {
        return {
            gender: {
                Male: 0,
                Female: 1,
                Unspecified: 2
            },
            genderLabels: {
                Male: this.$t("gender.male"),
                Female: this.$t("gender.female"),
                Unspecified: this.$t("gender.unspecified")
            }
        }
    },
    computed: {
        genderTypeList() {
            return Object.entries(this.gender).map(e => ({ id: e[1], name: this.genderLabels[e[0]] }));
        }
    }
};