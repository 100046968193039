<template>
  <b-form-group
    class="mb-0 no-delimiter"
    :label-cols="labelCols"
    :label-cols-sm="labelColsSm"
    :label-class="labelClass"
  >
    <template slot="label">
      <div>
        {{ label }}
      </div>
    </template>
    <p class="form-control-plaintext">
      <slot>{{ value }}</slot>
    </p>
  </b-form-group>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
      required: true,
    },
    labelCols: {
      type: String,
      default: "12",
    },
    labelColsSm: {
      type: String,
      default: "6",
    },
    labelClass: {
      type: String,
    },
    value: null,
  },
};
</script>