<template>
  <b-row>
    <b-col cols="12" md="6">
      <form-field-input
        v-if="countryField"
        :value="input.AddressInfo.countryId"
        :field="countryField"
        :formConfig="formConfig"
        :input="input"
        @input="handleFieldInput"
      />
      <template v-if="enableAddressAutocomplete">
        <form-field-input
          :field="zipcodeField"
          :formConfig="formConfig"
          :input="input"
          @input="handleFieldInput"
        />

        <label v-if="cannotFindAddressErrorVisibility" class="text-danger">
          {{ $t("cannot_find_address") }}
        </label>

        <b-overlay :show="addressLoading">
          <form-field-input
            v-if="cityField"
            :value="input.AddressInfo.city"
            :field="cityField"
            :formConfig="formConfig"
            :input="input"
            @input="handleFieldInput"
          />
          <form-field-input
            v-if="address1Field"
            :value="input.AddressInfo.address1"
            :field="address1Field"
            :formConfig="formConfig"
            :input="input"
            @input="handleFieldInput"
          />
        </b-overlay>
        <form-field-input
          v-if="address2Field"
          :field="address2Field"
          :formConfig="formConfig"
          :input="input"
          @input="handleFieldInput"
        />
      </template>
      <template v-else>
        <form-field-input
          v-if="address1Field"
          :field="address1Field"
          :formConfig="formConfig"
          :input="input"
          @input="handleFieldInput"
        />
        <form-field-input
          v-if="address2Field"
          :field="address2Field"
          :formConfig="formConfig"
          :input="input"
          @input="handleFieldInput"
        />
        <form-field-input
          v-if="houseNumberField"
          :field="houseNumberField"
          :formConfig="formConfig"
          :input="input"
          @input="handleFieldInput"
        />
        <form-field-input
          v-if="houseNumberExtensionField"
          :field="houseNumberExtensionField"
          :formConfig="formConfig"
          :input="input"
          @input="handleFieldInput"
        />
      </template>
    </b-col>

    <b-col cols="12" md="6">
      <template v-if="enableAddressAutocomplete">
        <form-field-input
          v-if="houseNumberField"
          :field="houseNumberField"
          :formConfig="formConfig"
          :input="input"
          @input="handleFieldInput"
        />
        <form-field-input
          v-if="houseNumberExtensionField"
          :field="houseNumberExtensionField"
          :formConfig="formConfig"
          :input="input"
          @input="handleFieldInput"
        />
      </template>
      <template v-else>
        <form-field-input
          v-if="zipcodeField"
          :field="zipcodeField"
          :formConfig="formConfig"
          :input="input"
          @input="handleFieldInput"
        />
        <form-field-input
          v-if="cityField"
          :field="cityField"
          :formConfig="formConfig"
          :input="input"
          @input="handleFieldInput"
        />
      </template>
    </b-col>
  </b-row>
</template>

<script>
import axios from "axios";
import moduleCommonMixin from "./moduleCommonMixin";
import { appSettings } from "@/appSettings";
export default {
  mixins: [moduleCommonMixin],
  props: {
    formConfig: {
      type: Object,
      required: true,
    },
    formModule: {
      type: Object,
      required: true,
    },
    input: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      addressLoading: false,
      cannotFindAddressErrorVisibility: false,
    };
  },
  computed: {
    countryField() {
      return this.getFormField("AddressInfo.countryId");
    },
    zipcodeField() {
      return this.getFormField("AddressInfo.zipcode");
    },
    cityField() {
      return this.getFormField("AddressInfo.city");
    },
    address1Field() {
      return this.getFormField("AddressInfo.address1");
    },
    address2Field() {
      return this.getFormField("AddressInfo.address2");
    },
    houseNumberField() {
      return this.getFormField("AddressInfo.houseNumber");
    },
    houseNumberExtensionField() {
      return this.getFormField("AddressInfo.houseNumberExtension");
    },
    isDutch() {
      return (
        (this.countryField &&
          this.input &&
          this.input.AddressInfo &&
          this.input.AddressInfo.countryId &&
          this.input.AddressInfo.countryId == 528) ||
        (!this.countryField && this.formConfig.locale == "nl-NL")
      );
    },
    enableAddressAutocomplete() {
      return (
        this.isDutch &&
        this.zipcodeField &&
        (this.cityField || this.address1Field)
      );
    },
  },
  methods: {
    loadAddressData(zipcode, houseNumber) {
      zipcode = zipcode.replace(/\s+/g, "");
      this.cannotFindAddressErrorVisibility = false;
      this.addressLoading = true;
      axios
        .get(`${appSettings.FoysPubRegistrationsUrl}/geocoding/street`, {
          params: {
            zipcode,
            houseNumber,
          },
        })
        .then((response) => {
          if (response.data) {
            this.$nextTick(() => {
              this.cannotFindAddressErrorVisibility = false;

              if (response.data.city && this.cityField) {
                this.handleFieldInput({
                  key: "AddressInfo.city",
                  value: response.data.city,
                });
              }
              if (response.data.streetName && this.address1Field) {
                this.handleFieldInput({
                  key: "AddressInfo.address1",
                  value: response.data.streetName,
                });
              }
            });
          } else {
            this.cannotFindAddressErrorVisibility = true;
          }
        })
        .catch(() => {
          this.cannotFindAddressErrorVisibility = true;
        })
        .finally(() => {
          this.addressLoading = false;
        });
    },
  },
  watch: {
    "input.AddressInfo.zipcode"(val) {
      if (
        this.enableAddressAutocomplete &&
        val &&
        (val.length == 6 || val.length == 7)
      ) {
        this.loadAddressData(
          this.input.AddressInfo.zipcode,
          this.input.AddressInfo.houseNumber
        );
      }
    },
    "input.AddressInfo.houseNumber"(val) {
      if (
        this.enableAddressAutocomplete &&
        val &&
        this.input.AddressInfo.zipcode &&
        (this.input.AddressInfo.zipcode.length == 6 ||
          this.input.AddressInfo.zipcode.length == 7)
      ) {
        this.loadAddressData(
          this.input.AddressInfo.zipcode,
          this.input.AddressInfo.houseNumber
        );
      }
    },
  },
  created() {
    if (!this.input.AddressInfo) {
      this.$set(this.input, "AddressInfo", {});
    }
  },
};
</script>