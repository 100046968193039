var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-btn',{staticClass:"mb-3",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.value.push({})}}},[_vm._v(_vm._s(_vm.$t("add_parent_info")))]),(_vm.value.length)?_c('b-table',{staticClass:"mt-2",attrs:{"fields":_vm.fields,"items":_vm.value,"borderless":""},scopedSlots:_vm._u([{key:"cell(parentName)",fn:function(ref){
var item = ref.item;
return [_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"trim":""},model:{value:(item.parentName),callback:function ($$v) {_vm.$set(item, "parentName", $$v)},expression:"item.parentName"}}),_vm._l((errors),function(error){return _c('p',{key:error,staticClass:"text-danger mb-0"},[_vm._v(" "+_vm._s(error)+" ")])})]}}],null,true)})]}},{key:"cell(relationship)",fn:function(ref){
var item = ref.item;
return [_c('b-form-input',{attrs:{"trim":""},model:{value:(item.relationship),callback:function ($$v) {_vm.$set(item, "relationship", $$v)},expression:"item.relationship"}})]}},{key:"cell(email)",fn:function(ref){
var item = ref.item;
return [_c('validation-provider',{attrs:{"rules":{
              email: true,
              required: !!item.receiveEmails,
            },"name":_vm.$t('email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"trim":""},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}}),_vm._l((errors),function(error){return _c('p',{key:error,staticClass:"text-danger mb-0"},[_vm._v(" "+_vm._s(error)+" ")])})]}}],null,true)})]}},{key:"cell(phone)",fn:function(ref){
            var item = ref.item;
return [_c('b-form-input',{attrs:{"trim":""},model:{value:(item.phone),callback:function ($$v) {_vm.$set(item, "phone", $$v)},expression:"item.phone"}})]}},{key:"cell(receiveEmails)",fn:function(ref){
            var item = ref.item;
return [_c('b-form-checkbox',{model:{value:(item.receiveEmails),callback:function ($$v) {_vm.$set(item, "receiveEmails", $$v)},expression:"item.receiveEmails"}})]}},{key:"cell(action)",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('b-link',[_c('b-icon-trash',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.value.splice(_vm.value.indexOf(item), 1)}}})],1)],1)]}}],null,false,2151929210)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }