<template>
  <div>
    <b-form-file
      v-model="file"
      :placeholder="$t('choose_file')"
      accept="image/*"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      file: null,
    };
  },
  watch: {
    file: {
      async handler() {
        var reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => {
          var base64 = reader.result;
          var dto = {
            base64: base64.toString().replace(/^.*,/, ''),
            fileName: this.file.name,
          };

          this.$emit("input", dto);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      },
    },
  },
};
</script>
