<template>
  <b-row>
    <b-col cols="12" md="6">
      <b-form-group label-cols-sm="4" v-if="servicesField">
        <template #label>
          {{ servicesFieldLabel }}
          <span v-if="servicesField.required">* </span>
          <span
            v-if="servicesFieldDescription"
            v-b-popover.hover.top="servicesFieldDescription"
          >
            <b-icon-question-circle variant="info" />
          </span>
        </template>
        <validation-provider
          :rules="{ required: servicesField.required }"
          v-slot="{ errors }"
          :name="servicesFieldLabel"
        >
          <b-form-checkbox-group
            v-if="availableServices.length > 0"
            :options="availableServices"
            value-field="id"
            text-field="name"
            v-model="serviceIds"
            stacked
          />

          <span v-else>{{ $t("no_services_found_for_age") }}</span>

          <p v-for="error in errors" :key="error" class="text-danger mb-0">
            {{ error }}
          </p>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import moduleCommonMixin from "./moduleCommonMixin";
import { planMixin } from "@/shared";
import { BIconQuestionCircle } from "bootstrap-vue";

export default {
  mixins: [moduleCommonMixin, planMixin],
  components: {
    BIconQuestionCircle,
  },
  props: {
    formConfig: {
      type: Object,
      required: true,
    },
    formModule: {
      type: Object,
      required: true,
    },
    input: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      serviceIds: [],
    };
  },
  computed: {
    availableServices() {
      var serviceStartDate =
        this.input.PlanAssignment?.startDate || new Date().toISOString();

      if (!this.servicesField.options) return [];

      var services = this.servicesField.options.filter(
        (x) =>
          new Date(x.startDate) <= new Date(serviceStartDate) &&
          new Date(x.endDate) >= new Date(serviceStartDate)
      );

      if (this.isBirthDateFilled) {
        var personAgeToday = this.getPersonAge();

        services = services.filter((service) => {
          if (!service.hasAgeSettings) return true;

          var personAgeToCheck = personAgeToday;

          if (service.ageDeterminationDate) {
            personAgeToCheck = this.getPersonAge(
              new Date(service.ageDeterminationDate)
            );
          }

          return (
            service.startAge <= personAgeToCheck &&
            service.endAge >= personAgeToCheck
          );
        });
      }

      return services.map((service) => {
        const price = service.price.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });

        return {
          id: service.id,
          name: `${service.name} (€ ${price})`,
        };
      });
    },
    personBirthDate() {
      return this.input.BirthDate;
    },
    isBirthDateFilled() {
      return !!this.personBirthDate;
    },
    servicesFieldLabel() {
      return this.servicesField.customLabel || this.servicesField.label;
    },
    servicesFieldDescription() {
      return this.servicesField.description;
    },
    servicesField() {
      return this.getFormField("ServiceIds");
    },
  },
  methods: {
    getPersonAge(referenceDate) {
      referenceDate ??= new Date();
      var birthDate = new Date(this.personBirthDate);
      var age = referenceDate.getFullYear() - birthDate.getFullYear();
      var m = referenceDate.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && referenceDate.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
  },
  watch: {
    serviceIds() {
      this.handleFieldInput({
        value: this.serviceIds,
        key: this.servicesField.key,
      });
    },
  },
};
</script>