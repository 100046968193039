export const planMixin = {
    methods: {
        getPlanDisplayLabel(plan, hidePrices) {
            if (hidePrices) return plan.name

            var name = plan.name;

            var price = plan.price.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            });

            var registrationFee = plan.registrationFee
                ? plan.registrationFee.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })
                : null;


            var remittanceFee = plan.remittanceFee
                ? plan.remittanceFee.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })
                : null;

            var transferFee = plan.transferFee
                ? plan.transferFee.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })
                : null;

            var discountFee = plan.discountFee
                ? plan.discountFee.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })
                : null;

            var administrationFee = plan.administrationFee
                ? plan.administrationFee.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })
                : null;


            var pricePart =
                `(€ ${price}` +
                (registrationFee
                    ? ` + € ${registrationFee} ${this.$t("registration_fee")}`
                    : "") +
                (remittanceFee
                    ? ` + € ${remittanceFee} ${plan.remittanceDescription || this.$t("remittance_fee")}`
                    : "") +
                (transferFee
                    ? ` + € ${transferFee} ${this.$t("transfer_fee")}`
                    : "") +
                (discountFee
                    ? ` + € ${discountFee} ${this.$t("discount_fee")}`
                    : "") +
                (administrationFee
                    ? ` + € ${administrationFee} ${this.$t("administration_fee")}`
                    : "") +
                ")";

            return `${name} ${pricePart}`;
        },
    }
}