import {
    parse,
    format, formatISO,
    isValid,
    startOfDay, endOfDay,
    isEqual, compareAsc,
    isFuture, isPast,
    addDays,
    setHours, setMinutes, setSeconds
} from 'date-fns';

import { _getDefaultDate, _parseDateIso } from '@/shared/filters';

export const dateTimeService = {
    methods: {


        // Parsing date
        parseDateIso(value) {
            return _parseDateIso(value);
        },
        parseDateFormat(value, dateFormat) {
            if (!value) {
                return null;
            }

            if (value instanceof Date) {
                return _getDefaultDate(value);
            }

            let date = parse(value, dateFormat || 'dd-MM-yyyy', new Date());
            return _getDefaultDate(date);
        },


        // Manipulate with date
        addDaysToDate(value, days) {
            let date = this.parseDateIso(value);
            return date ? addDays(date, days) : null;
        },
        startOfDate(value) {
            var date = this.parseDateIso(value);
            return date ? startOfDay(date) : null;
        },
        endOfDate(value) {
            var date = this.parseDateIso(value);
            return date ? endOfDay(date) : null;
        },
        areDatesEqual(date1, date2) {
            let dateLeft = this.parseDateIso(date1);
            let dateRight = this.parseDateIso(date2);
            if (!dateLeft || !dateRight) {
                return false;
            }
            return isEqual(dateLeft, dateRight);
        },
        sortDatesAsc(date1, date2) {
            let dateLeft = this.parseDateIso(date1);
            let dateRight = this.parseDateIso(date2);
            if (dateLeft && dateRight) {
                return compareAsc(dateLeft, dateRight);
            }
            if (!dateLeft) {
                return 1;
            } else {
                return -1;
            }
        },
        isDateInFuture(value) {
            var date = this.parseDateIso(value);
            return date ? isFuture(date) : false;
        },
        isDateInPast(value) {
            var date = this.parseDateIso(value);
            return date ? isPast(date) : false;
        },
        isoDateToUtc(isoDate) {
            if (!isoDate) {
                return null;
            }
            if (isoDate instanceof Date) {
                isoDate = this.formatDateIso(isoDate);
            }
            let parts = isoDate.split('-');
            let date = new Date(Date.UTC(Number.parseInt(parts[0]), Number.parseInt(parts[1] - 1), Number.parseInt(parts[2]), 0, 0, 0));
            return isValid(date) ? date : null;
        },
        setDateHours(value, hours) {
            var date = this.parseDateIso(value);
            return date ? setHours(date, hours) : null;
        },
        setDateMinutes(value, minutes) {
            var date = this.parseDateIso(value);
            return date ? setMinutes(date, minutes) : null;
        },
        setDateSeconds(value, seconds) {
            var date = this.parseDateIso(value);
            return date ? setSeconds(date, seconds) : null;
        },


        // Formating date
        formatDate(value, dateFormat, defaultValue) {
            if (!dateFormat) {
                console.log('formatDate method call missing dateFormat argument.');
                return (defaultValue || null);
            }
            var date = this.parseDateIso(value);
            return date ? format(date, dateFormat) : (defaultValue || null);
        },
        // Returns `1990-01-21T03:00:00+03:00`
        formatDateIso(value, defaultValue) {
            var date = this.parseDateIso(value);
            return date ? formatISO(date) : (defaultValue || null);
        },
        // Returns `1990-01-21T00:00:00.000Z`
        formatDateToIsoString(value, defaultValue) {
            var date = this.parseDateIso(value);
            return date ? date.toISOString() : (defaultValue || null);
        }, 

        toJsonWithoutOffset(date) {
            return format(date, 'yyyy-MM-dd\'T\'HH:mm')
        },

        // Helpers
        getTodayIso() {
            return formatISO(this.startOfDate(new Date()));
        },
        getTodayUtc() {
            return this.isoDateToUtc(this.startOfDate(new Date()));
        }

    }
};
